const keycodes = {
  backspace: 8,
  tab: 9,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  delete: 46,
};

export { keycodes };
