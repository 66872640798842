import React from 'react'
import CrosswordThumbnail from './crossword_thumbnail'

class CrosswordAlbum extends React.Component {

	render() {
		let thumbs = this.props.data.map((thumb, key) => {
			return (
				<CrosswordThumbnail key={key} data={thumb} title={key+1} />
			)
		});
		return(
			<>
				<div className="row" id="thumbs-holder">
					{thumbs}
				</div>
			</>
		)
	}
}


export default CrosswordAlbum;
