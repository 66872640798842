import React from 'react'
import Crossword from './crossword';
import Loading from './loading';
import SubmitForm from './submit';
import {Helmet} from 'react-helmet';
//import file from './theg_20171103_crossword.json'
import Layout from './layout'

function Archivist(props) {
	return (<>&nbsp;&#47;&nbsp;archived by&nbsp;<span>{props.name}</span></>)
}

class ShowCrossword extends React.Component {

	constructor(props) {
		super(props)
		this.state = { data: {}, submitFormFlag: false }
		this.crossword_id = this.props.match.params.name
		this.submitForm = this.submitForm.bind(this)
	}


	componentDidMount() {
		//this.setState( {
		//	data: file
		//})
		const ENDPOINT = 'https://63r48wjekh.execute-api.ca-central-1.amazonaws.com/prod/crosswords/';
		fetch(ENDPOINT, {
			method: 'POST',
			headers: {
				'Content-Type': 'applications/json'
			},
			body: JSON.stringify({"c_id" : this.crossword_id})
		})
			.then((response) => {
				if(response.ok) {
					return response.json();
				}
				else {
					throw new Error('Something went wrong');
				}
				})
			.then(response => {
				this.setState({
					data: JSON.parse(response.body)
				})
			})
			.catch(err=>{ alert(err)});
	}

	submitForm() {
		this.setState({
			submitFormFlag: true
		})
	}

	render() {
		if(this.state.data.name !== undefined) {
			return(
			  <Layout
				  title={this.state.data.theme + " Crossword"}
					wrapperClass="crossword"
					description="Crosswords from the Universityr of Calgary's Student Newspaper"
					keywords="AWS, Linux, Java, Javascript, React, Python, Pandas, Machine Learning">
				  <div id="crossword-container">
							<div className="row gauntlet-header-row">
										<div className="col-sm-12" id="crossword-info-holder">
											<h1 id="crossword-theme">
												{this.state.data.theme? this.state.data.theme + " Crossword" : ""}
											</h1>
										<div className="credits">
												<h5>by&nbsp;<span>{this.state.data.author}</span></h5>
												<h5>{this.state.data.archivist ? <Archivist name={this.state.data.archivist} />: null}</h5>
											</div>
										</div>
								</div>
							<Crossword data={this.state.data} submitForm={this.submitForm}/>
							{this.state.submitFormFlag? <SubmitForm /> : null }
				  </div>
			  </Layout>
			)
		}
		else {
			return <Loading />
		}

	}
}

export default ShowCrossword;
