import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom'
import React from 'react';
import HomePage from './pages/HomePage'
import ShowCrossword from './components/crosswords/showcrossword'

class App extends React.Component {

	render() {
			return (
				<Router>
					<Switch>
						<Route path="/" component={HomePage} exact />
						<Route path="/crosswords/:name" component={ShowCrossword} />
					</Switch>
				</Router>
			)
	}
}

export default App;
