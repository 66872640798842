import React from 'react'


function NavItem(props) {
	return (
		  <a rel="noopener" target="_blank" className="page-link" href={props.to}>{props.name}</a>
	)
}

export default NavItem;
