import React from 'react'

class CrosswordThumbnail extends React.Component {
	extractTitle(dateStr) {
		let monthDict = {
			"01": "Jan",
			"02": "Feb",
			"03": "Mar",
			"04": "Apr",
			"05": "May",
			"06": "Jun",
			"07": "Jul",
			"08": "Aug",
			"09": "Sep",
			"10": "Oct",
			"11": "Nov",
			"12": "Dec"
		}

		let year = dateStr.substring(0, 4);
		let month = monthDict[dateStr.substring(4,6)];
		let day = dateStr.substring(6);

		return `${month} ${day}, ${year}`;
	}

	render() {
		return(
			<div className="col-sm-4 col-xs-6 col-md-3 crossword-thumb-holder">
				<div className="row">
					<div className="col-xs-12">
						<div className="crossword-thumb">
							<a href={"/crosswords/" + this.props.data}>
								{this.extractTitle(this.props.data)}
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}


export default CrosswordThumbnail;
