import React from 'react'
import Nav from './nav'
import {Helmet} from "react-helmet";


class Layout extends React.Component {

	render() {
		const blog_name = "Gauntlet Crosswords"

		return (
			<>
				<Helmet>
                <title>{blog_name} - {this.props.title || ""}</title>
					 <meta name="description" content={this.props.description} />
					 <meta name="keywords" content={this.props.keywords} />
            </Helmet>

				<header className="site-header" role="banner">
				  <div className={this.props.wrapperClass + "-wrapper"}>
					  <a className="site-title"  href="/">{blog_name}</a>
						<Nav />
					</div>
				</header>
			   <main className="page-content" aria-label="Content" id={this.props.wrapperClass}>
					<div className={this.props.wrapperClass + "-wrapper"}>
							{this.props.children}
					</div>
				 </main>

			</>
		)
	}
}


export default Layout;
