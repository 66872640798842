import React from 'react';

class SubmitForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = { 
			name: "",
			email: ""
		}

		this.nameHandler = this.nameHandler.bind(this)
		this.emailHandler = this.emailHandler.bind(this)
		this.submitAnswer = this.submitAnswer.bind(this)
		this.cancelSubmit = this.cancelSubmit.bind(this)
	}

	nameHandler(e) {
		this.setState({
			name: e.target.value
		})
	}

	emailHandler(e) {
		this.setState({
			email: e.target.value
		})
	}

	submitAnswer(e) {
		alert("Submit")
	}

	cancelSubmit(e) {
		alert("Cancel")
	}

	render() {
		return (
			<div id="submit-form-back">
				<div id="submit-form">
					<div id="sf-header">Submit Your Answer</div>
					<div id="sf-inputs-holder">
						<label htmlFor="name">Name</label>
						<input type="text" placeholder="Name" onChange={this.nameHandler}
							value={this.state.name} />

						<label htmlFor="email">Email</label>
						<input type="text" placeholder="Email" onChange={this.emailHandler}
							value={this.state.email} />

						<div id="sf-btn-holder">
							<button onClick={this.submitAnswer}>Submit</button>
							<button onClick={this.cancelSubmit}>Cancel</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SubmitForm;
