import React, { Component } from 'react';
import { ConfirmButton } from './confirm-button';

const buttonClassName = 'button button--primary';
const buttonCurrentClassName = 'button--crossword--current';
const buttonGenericClassName = 'button--secondary';

class Controls extends Component {
  render() {
    //const hasSolutions = this.props.hasSolutions;
	 const hasSolutions = true;
	 const alreadyOver = this.props.alreadyOver;
    const hasFocus = this.props.clueInFocus;
    const controls = {
      clue: [],
      grid: [],
    };

    // GRID CONTROLS
    controls.clue.unshift(
      <ConfirmButton
        //className={`${buttonClassName} ${buttonGenericClassName} clear-btn`}
		  className="btn btn-danger"
        onClick={this.props.crossword.onClearAll.bind(
          this.props.crossword,
        )}
        key="clear"
        data-link-name="Clear all"
        text="Clear all"
      />,
    );

	 if (false) {
		controls.clue.unshift(
        <ConfirmButton
          //className={`${buttonClassName} ${buttonGenericClassName}`}
			 className="btn btn-info"
          onClick={this.props.crossword.onSolution.bind(
            this.props.crossword,
          )}
          key="solution"
          data-link-name="Reveal all"
          text="Reveal all"
        />,
      );
	 }

    if (!alreadyOver) {
      controls.grid.unshift(
        <ConfirmButton
          //className={`${buttonClassName} ${buttonGenericClassName} submit-btn`}
			 className="btn btn-success"
          onClick={this.props.crossword.onCheckAll.bind(
            this.props.crossword,
          )}
          key="checkAll"
          data-link-name="Check all"
          text="Check All & Submit"
        />,
      );
    }

    // HIGHLIGHTED CLUE CONTROLS  - published solution
    if (hasFocus) {
      controls.clue.unshift(
        <button
          //className={`${buttonClassName} ${buttonCurrentClassName} `}
			 className="btn btn-warning"
			 type="button"
          onClick={this.props.crossword.onClearSingle.bind(
            this.props.crossword,
          )}
          key="clear-single"
          data-link-name="Clear this"
        >
                    Clear
        </button>,
      );

      // anagram helper
      //controls.clue.push(
      //  <button
      //    className={`${buttonClassName} ${buttonCurrentClassName}`}
      //    onClick={this.props.crossword.onToggleAnagramHelper.bind(
      //      this.props.crossword,
      //    )}
      //    key="anagram"
      //    data-link-name="Show anagram helper"
      //  >
      //              Anagram helper
      //  </button>,
      //);

		if (alreadyOver) {
        controls.clue.unshift(
          <button
            //className={`${buttonClassName} ${buttonCurrentClassName}`}
			   className="btn btn-info"
            onClick={this.props.crossword.onCheat.bind(
              this.props.crossword,
            )}
            key="cheat"
            data-link-name="Reveal this"
          >
                        Reveal
          </button>,
        );
		}

      if (hasSolutions) {
        controls.clue.unshift(
          <button
            //className={`${buttonClassName} ${buttonCurrentClassName}`}
				className="btn btn-primary"
            onClick={this.props.crossword.onCheck.bind(
              this.props.crossword,
            )}
            key="check"
            data-link-name="Check this"
          >
                        Check
          </button>,
        );
      }
    }

    return (
      <div className="crossword__controls">
        <div className="crossword_controls crossword__controls__clue">{controls.clue}</div>
        <div className="crossword_controls crossword__controls__grid">{controls.grid}</div>
      </div>
    );
  }
}

export { Controls };
