import React from 'react'

class Loading extends React.Component {

	constructor(props) {
		super(props)
		this.state = { data: {}, submitFormFlag: false }
	}


	componentDidMount() {
		this.setState( {
			data: {} 
		})
	}

	render() {
			return (
				<div className="loading-spinner">
			</div>
			)
	}
}

export default Loading;


