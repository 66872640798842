import React from 'react'
import CrosswordAlbum from './../components/crossword_album'
import Loading from './../components/crosswords/loading'
import Layout from './../components/crosswords/layout'



class HomePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: []
		}
	}


	componentDidMount() {
		this.setState( {
			data: []
		})
		//const ENDPOINT = 'https://djqjq6adl9.execute-api.us-east-1.amazonaws.com/default/theG_getA'
		const ENDPOINT = 'https://63r48wjekh.execute-api.ca-central-1.amazonaws.com/prod/'
		fetch(ENDPOINT, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({'b_id':'CROSS'})
		})
			.then(response => response.json())
			.then(response => {
				this.setState({
					data: JSON.parse(response.body)
				})
			})
			.catch(err=>{ alert("Error:: " + err)});
	}


	render() {

		if(this.state.data.length === 0) {
			//we haven't fetched the list yet
			return (
				<Loading />
			)
		}

		else {
			return(
			  <Layout
				  title="Main Page"
					wrapperClass="main"
					description="Crosswords from the Universityr of Calgary's Student Newspaper"
					keywords="AWS, Linux, Java, Javascript, React, Python, Pandas, Machine Learning">
				  <div className="home">
							<CrosswordAlbum data={this.state.data} />
				  </div>
			  </Layout>
			)
		}
	}
}


export default HomePage;
